import styled from 'styled-components'

import src from './logo.png'

const CustomLogo = styled.img.attrs({
  src,
})`
  margin: 0;
  height: 35px;
`

export default CustomLogo
